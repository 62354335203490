import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
import logo from './media/EFFY-Logo.svg';

import Particles from "./Particles.js"


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activateHeader: this.props.activateHeader,
            //accountButtonLink: <Link to="/account"><div className="button icon-profile"></div></Link>
        };

        //this.scrollDown = this.scrollDown.bind(this);
        this.logout = this.logout.bind(this);
        this.accountSettings = this.accountSettings.bind(this);
    }

    componentDidMount() {
        console.log("did Mount");

    }

    componentWillUnmount() {
        console.log("will Unmount");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");
            
        return ({ 
            activateHeader: nextProps.activateHeader
        })
    }

    scrollDown() {
        window.scroll(0, window.innerHeight);
    }

    async logout() {
        this.props.setloggedIn(false);
        //sessionStorage.removeItem("token");
        //console.log(document.cookie);
        

        if(process.env.NODE_ENV === 'production') {
            document.cookie = "serviceToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.tecomon.net; path=/;";
        } else {
            document.cookie = "serviceToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=localhost; path=/;";
        }

        
        localStorage.removeItem("gatewaycount"); 
        localStorage.removeItem("devicecount");
        localStorage.removeItem("servicecount");
        

        /*await fetch('https://spaas.tecomon.net/API/SECURE/logout.php', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                //mode: "cors", // no-cors, *cors, same-origin
                //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                //credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    //'Authorization': 'Basic '+btoa('test:test'), 
                },
                //redirect: "follow", // manual, *follow, error
                //referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                //body: JSON.stringify(data), // body data type must match "Content-Type" header
            });*/
    }

    async accountSettings() {
        console.error("account settings");

        /*if(window.location.pathname === "/") {
            this.state.accountButtonLink = <Link to="/account"><div className="button icon-profile"></div></Link>;
        } else {
            this.state.accountButtonLink = <Link to="/"><div className="button icon-profile"></div></Link>;
        }*/
    }



    render() {
       

        if(this.state.activateHeader === false) {
            return(
                <div className = "Header">
                    <Particles/>
                     
                    <img src={logo} width="50%" alt="logo"/>
                    <h1>DataHub</h1>
                    <div className="App-caption">
                        Your IoT-Edge-Device-Cloud for Green Efficiency Building
                    </div>

                    <div className="button Header-scrollDownButton" onClick={this.scrollDown}>
                        <svg
                        width="80%"
                        height="80%"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.0001 3.67157L13.0001 3.67157L13.0001 16.4999L16.2426 13.2574L17.6568 14.6716L12 20.3284L6.34314 14.6716L7.75735 13.2574L11.0001 16.5001L11.0001 3.67157Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>
            )
        } else {
            return(
                    <div className = "Header Header-active">
                        <div className="Header-logo-active">
                            
                            <div className = "Header-Headline">
                                
                                    <Link to="/">
                                        <h1>DataHub</h1>
                                    </Link>
                                    
                                
                            </div>
                            
                            <div>
                                <Link to="/">
                                    <img id = "logoMenu" src={logo} width="80%" alt="logo"/>
                                </Link>
                            </div>
                        
                            <div>
                                <Link to="/">
                                    <div className = "button Header-Logout-Button " onClick={this.logout}>
                                        <div className = "icon-log-out"></div>
                                        <div className = "Header-Logout-Button-Text">Logout</div>
                                    </div>
                                </Link>

                                
                                <div className = "button Header-Settings-Button" onClick = { () => window.open('https://tecomon.net/', '_blank') } >
                                    <div className="button icon-profile"></div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
            )
        }
    }
}

export default Header;