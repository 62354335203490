import React, { useState, useEffect, useCallback } from 'react';
import './Card.css';
import Popup from './Popup.js';

const CardDevice = ({ name, token, unitNames, units, uid, zone }) => {
    const [popupActive, setPopupActive] = useState(false);
    const [emojiIcon, setEmojiIcon] = useState(null);
    const [emojiBorderColor, setEmojiBorderColor] = useState("var(--box-backgroundColor)");
    const [tagText, setTagText] = useState("");

    // API-Aufruf zum Laden der Einstellungen
    const loadSettings = useCallback(async () => {
        try {
            const response = await fetch(process.env.REACT_APP_PORTALDB_SERVICE + "/device?uid=" + uid, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            if (!response.ok) {
                throw new Error('HTTP error, status = ' + response.status);
            }
            const json = await response.json();
            setEmojiIcon(json.emoji);
            setTagText(json.tagText);
            setEmojiBorderColor(json.tagColor);
        } catch (err) {
            console.error("Failed to load popup settings");
            console.warn(err);
        }
    }, [uid, token]);

    useEffect(() => {
        loadSettings();
    }, [loadSettings]);

    const loadPopup = () => {
        setPopupActive(true);
    };

    const unloadPopup = () => {
        setPopupActive(false);
    };

    return (
        <div>
            <div className="Card" onClick={loadPopup}>
                <div className="Card-Header">{name}</div>
                <br></br>
                <br></br>
                <div style={{ borderColor: emojiBorderColor }} className="PopupDevice-EmojiIcon-Container">
                    {emojiIcon ? 
                        <div className="PopupDevice-EmojiIcon"> 
                            {emojiIcon} 
                        </div>  
                        :  
                        <div className="PopopDevice-Icon-Placeholder">
                            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 17C14.2091 17 16 15.2091 16 13H8C8 15.2091 9.79086 17 12 17Z" fill="currentColor" />
                                <path d="M10 10C10 10.5523 9.55228 11 9 11C8.44772 11 8 10.5523 8 10C8 9.44772 8.44772 9 9 9C9.55228 9 10 9.44772 10 10Z" fill="currentColor" />
                                <path d="M15 11C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11Z" fill="currentColor" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill="currentColor" />
                            </svg>
                        </div>
                    }
                </div>
            </div>

            {popupActive && 
                <Popup 
                    type="device" 
                    token={token} 
                    tagText={tagText} 
                    changeTagText={setTagText} 
                    borderColor={emojiBorderColor} 
                    changeBorderColor={setEmojiBorderColor} 
                    icon={emojiIcon} 
                    changeIcon={setEmojiIcon} 
                    category="device" 
                    unitNames={unitNames} 
                    units={units} 
                    uid={uid} 
                    name={name} 
                    zone={zone} 
                    data={{}}  // Du kannst hier zusätzliche Daten übergeben
                    deactivate={unloadPopup} 
                />
            }
        </div>
    );
};

export default CardDevice;
