import React, { useState, useEffect, useCallback } from 'react';
import './CardTemp.css';

import Popuptemp from './Popuptemp.js';

const CardZone = ({ name, uid ,token, devices }) => {
    const [popupActive, setPopupActive] = useState(false);
    const [emojiIcon, setEmojiIcon] = useState(null);
    const [emojiBorderColor, setEmojiBorderColor] = useState("var(--box-backgroundColor)");
    const [tagText, setTagText] = useState("");
    const [subdomain, setSubdomain] = useState(null);
    const [subdomainapi, setSubdomainapi] = useState(null);
    const [units, setUnits] = useState(null);
    const [deviceNames, setDeviceNames] = useState(null);
    const [alarmActive, setAlarmActive] = useState(false);
    const [warningActive, setWarningActive] = useState(false);
  // Funktion, um den Alarm zu toggeln
  const toggleAlarm = () => {
    setAlarmActive(!alarmActive);
  };

  // Funktion, um die Warnung zu toggeln
  const toggleWarning = () => {
    setWarningActive(!warningActive);
  };
    useEffect(() => {
           
    setUnits(devices.units);
    setDeviceNames(devices.unitNames);
    }, []);

    const loadPopup = () => {
        console.log("load Popup");
        setPopupActive(true);
    };

    const unloadPopup = () => {
        console.log("unload Popup");
        setPopupActive(false);
    };

    return (
        <div>
            <div className="Cardzone" onClick={loadPopup}>
                <div className="Cardzone-Header">Zone {name}</div>
                <div className="Cardzone-field">

                <table className="data-table">
        <thead>
          <tr>
            <th>RGA</th>
            <th>Verpacken</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="data-point">
                <div className="data-label">Rel. Feuchte:</div>
                <div className="data-value status">unset</div>
              </div>
              <div className="data-point">
                <div className="data-label">Temp:</div>
                <div className="data-value status">unset</div>
              </div>
              <div className="data-point">
                <div className="data-label">Luftqualität:</div>
                <div className="data-value">unset</div>
              </div>
            </td>
            <td>
              <div className="data-point">
                <div className="data-label">Rel. Feuchte:</div>
                <div className="data-value status">unset</div>
              </div>
              <div className="data-point">
                <div className="data-label">Temp:</div>
                <div className="data-value status">unset</div>
              </div>
              <div className="data-point">
                <div className="data-label">Luftqualität:</div>
                <div className="data-value">unset</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>


    
 

      <div className="bottom-section">
        {/* Alarm */}
        <div className="data-point-ar" onClick={alarmActive ? toggleAlarm : null}>

          <div className={`data-value-ar  ${alarmActive ? 'blink alarm' : ''}`}>
            {alarmActive ? 'Alarm' : ' Alarm'}
          </div>
        </div>

        {/* Warnung */}
        <div className="data-point-ar" onClick={warningActive ? toggleWarning : null} >

          <div className={`data-value-ar  ${warningActive ? 'blink warning' : ''}`}>
            {warningActive ? 'Warnung' : ' Warnung'}
          </div>
        </div>
    </div>



      </div>
            </div>

            {/*
            popupActive && (
                <Popuptemp
                    type="service"
                    name={name}
                    deactivate={unloadPopup}
                />
            )
            */}
        </div>
    );
};

export default CardZone;
