import React from 'react';

import './Main.css';
import Login from './Login.js'
import Dashboard from './Dashboard.js';
import Overview from './Overview.js';
import Dashboard_spezial from './Dashboard_spezial.js';
//import PropTypes from 'prop-types';
//import { withRouter } from 'react-router-dom';


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gatewayCountOverview: "",
            deviceCountOverview: "",
            serviceCountOverview: "",
            cloudBubbleRef: null,
            devices: []
        };

        this.changeGatewayCountOverview = this.changeGatewayCountOverview.bind(this);
        this.changeDeviceCountOverview = this.changeDeviceCountOverview.bind(this);
        this.changeServiceCountOverview = this.changeServiceCountOverview.bind(this);
        this.setDevices = this.setDevices.bind(this);

        this.setCloudBubble = this.setCloudBubble.bind(this);
        this.cloudBubble = React.createRef();
        console.log(this)
       

    /*static propTypes = {
            match: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired
          }*/
    }
    componentDidMount() {
        console.log("did Mount");

        //const { match, location, history } = this.props;
        //console.error(location.pathname);
    }

    componentWillUnmount() {
        console.log("will Unmount");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log("get derived state");

        return { }; //return new state to actual component
    }

    changeGatewayCountOverview(count) {
        this.setState({gatewayCountOverview: count});
    }

    changeDeviceCountOverview(count) {
        this.setState({deviceCountOverview: count});
    }

    changeServiceCountOverview(count) {
        this.setState({serviceCountOverview: count});
    }

    setCloudBubble(cloudBubbleRef) {
        this.setState({cloudBubbleRef: cloudBubbleRef});
    }

    setDevices(devices) { 
        this.setState({devices: devices});
    }


    render() {

        return(
            <div className = "Main">
                <main className="App-main">

                    { 
                        this.props.loggedIn ? 

                            <>{
                            this.props.username === "Tecomon471671" ?
                            <div> 
                               <Dashboard_spezial  activateDashboard = { this.props.loggedIn } /> 
                               
                            </div> 
                            :
                            <div> 
                                <Overview devices = { this.state.devices } setCloudBubble = { this.setCloudBubble } gatewayCount={this.state.gatewayCountOverview} deviceCount={this.state.deviceCountOverview} serviceCount={this.state.serviceCountOverview} /> 
                                <Dashboard devices = { this.state.devices } setDevices = { this.setDevices } cloudBubbleRef = { this.state.cloudBubbleRef } activateDashboard = { this.props.loggedIn } changeGatewayCount={this.changeGatewayCountOverview} changeDeviceCount={this.changeDeviceCountOverview} changeServiceCount={this.changeServiceCountOverview} /> 
                            </div> 
                            }</>
                        
                        : 
                        <Login activateLogin={this.props.loggedIn} setloggedIn={this.props.setloggedIn}  setUsername={this.props.setUsername} /> 
                    }
  
                </main>
            </div>
        )
    }
}

export default Main;