import React, { useEffect, useState, useRef } from 'react';
import './Dashboard.css';
import { jwtDecode } from 'jwt-decode';
import CardZone from './CardZone.js';
import CardDeviceTemp from './CardDeviceTemp.js';
import Tempoimg from './Tempoimg.js';

const Dashboard = ({ activateDashboard }) => {
  const [cardsDevices, setCardsDevices] = useState([]);
  const [cardsZones, setCardsZones] = useState([]);
  const intervalRef = useRef(null);
  const tokenCookie = (() => {
    const name = 'serviceToken=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let cookie of cookieArray) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  })();

  const token = jwtDecode(tokenCookie);

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenCookie}`,
    },
  };

  // Diese Funktion führt alle API-Aufrufe auf einmal aus
  const fetchData = async () => {
    try {
      // Gateway-Typen abrufen
      const gatewayTypesResponse = await fetch(`${process.env.REACT_APP_PORTAL_SERVICE}/gateways`, fetchOptions);
      if (!gatewayTypesResponse.ok) throw new Error(`HTTP error, status = ${gatewayTypesResponse.status}`);
      const gatewayTypesData = await gatewayTypesResponse.json();
      const gatewayTypes = gatewayTypesData.gateways;
      
      // Alle Gateways abrufen
      const uids = [];
      const names = [];
      const gatewayData = [];

      const fetchAllGateways = async (gatewayType) => {
        const response = await fetch(
          `${process.env[`REACT_APP_${gatewayType.toUpperCase()}_SERVICE`]}/gateways`,
          fetchOptions
        );
        if (!response.ok) throw new Error(`HTTP error, status = ${response.status}`);
        const data = await response.json();
        uids.push(...data.UIDs);
        names.push(...data.names);
        data.UIDs.forEach(() => gatewayData.push(gatewayType));
      };

      // Alle Gateways für jeden Typ abrufen
      await Promise.all(gatewayTypes.map(fetchAllGateways));

      // Geräte für jedes Gateway abrufen
      const allDevices = [];
      const fetchGatewayDevices = async (gateway) => {
        const options = { ...fetchOptions, method: 'POST' };
        const response = await fetch(
          `${process.env[`REACT_APP_${gateway.gatewayType.toUpperCase()}_SERVICE`]}/devices`,
          {
            ...options,
            body: JSON.stringify({ gatewayUID: gateway.gatewayUID }),
          }
        );
        if (!response.ok) throw new Error(`HTTP error, status = ${response.status}`);
        const data = await response.json();
        data.UIDs.forEach((uid, index) => {
          allDevices.push({
            deviceUID: uid,
            deviceName: data.names[index],
            deviceUnits: data.units[index],
            unitNames: data.unitNames[index],
            deviceZone: data.zones[index],
          });
        });
      };

      // Gateways durchlaufen und Geräte abrufen
      const gateways = uids.map((uid, index) => ({ gatewayType: gatewayData[index], gatewayUID: uid, gatewayName: names[index] }));
      await Promise.all(gateways.map(fetchGatewayDevices));

      // Geräte und Zonen in den State setzen
      setCardsDevices(
        allDevices.map((device, index) => (
          <CardDeviceTemp
            key={index}
            token={tokenCookie}
            name={device.deviceName}
            zone={device.deviceZone}
            uid={device.deviceUID}
            unitNames={device.unitNames}
            units={device.deviceUnits}
          />
        ))
      );

      setCardsZones(
        Object.entries(
          allDevices.reduce((acc, device) => {
            if (device.deviceZone !== '') {
              if (!acc[device.deviceZone]) {
                acc[device.deviceZone] = [];
              }
              acc[device.deviceZone].push(device);
            }
            return acc;
          }, {})
        ).map(([zone, devicesInZone], index) => (
          <CardZone
            key={index}
            token={tokenCookie}
            name={zone}
            devices={devicesInZone.map((device) => ({
              name: device.deviceName,
              uid: device.deviceUID,
              unitNames: device.unitNames,
              units: device.deviceUnits,
            }))}
          />
        ))
      );

    } catch (err) {
      console.error('Fehler bei den API-Aufrufen:', err);
    }
  };

  useEffect(() => {
    // Einmaliger API-Aufruf bei Laden der Komponente
    fetchData();

    // Setze das Intervall, das alle 60 Sekunden die APIs aufruft
    intervalRef.current = setInterval(fetchData, 60000); // alle 60 Sekunden

    // Cleanup: Wenn die Komponente unmontiert wird, lösche das Intervall
    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, []); // Der Effekt wird nur einmal ausgeführt

  if (!activateDashboard) return null;

  return (
    <div className="Dashboard">
      <h2>Dashboard</h2>
      <h3>Übersicht</h3>
      <div className="GatewaysDash">
        <Tempoimg />
      </div>
      <h3>Zonen</h3>
      <div className="GatewaysDash">
        {cardsZones.length === 0 ? <p>Keine Geräte vorhanden</p> : cardsZones}
      </div>
      <h3>Devices</h3>
      <div className="GatewaysDash">
        {cardsDevices.length === 0 ? <p>Keine Geräte vorhanden</p> : cardsDevices}
      </div>
    </div>
  );
};

export default Dashboard;
