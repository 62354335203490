import React, { useState, useEffect, useCallback } from 'react';
import './Tempoimg.css';


const Tempoimg = ({ data }) => {


  
    return (
        <div  style={{ backgroundColor:'#3e7dd1e8', borderRadius: '20px', padding:'20px' }}>
            <div style={{ position: 'relative', minWidth: 480, maxWidth: 1057 }}>
                <img
                    src="/Tecomon471671_GR.png"
                    style={{ width: '100%', height: 'auto', minWidth: 480 }}
                    alt="Grundriss"
                />

            </div>
        </div>
    );
};

export default Tempoimg;

